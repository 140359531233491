import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import sendShoppingCart from '@api/shoppingCart'
import Button from '@objects/button'
import FriendlyCaptcha from '@objects/formfields/friendlycaptcha'
import TextField from '@objects/formfields/textField'
import CheckboxField from '@objects/formfields/checkboxField'
import RichTextEditor from '@objects/formfields/richtext'
import { useToggleComp } from '@hooks/index'
import useBasket from '@hooks/useBasket'
import { navigate } from 'gatsby'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(7, 0, 8, 0),
    display: 'inherit',
  },
}))

export default function OrderForm(): ReactElement {
  const intl = useIntl()
  const classes = useStyles()
  const { toggleOverlay } = useToggleComp()
  const { basket, clearBasket } = useBasket()
  const validationSchema = Yup.object({
    firstname: Yup.string().required(
      intl.formatMessage({ id: 'checkout.firstname.error.required' })
    ),
    lastname: Yup.string().required(
      intl.formatMessage({ id: 'checkout.lastname.error.required' })
    ),
    company: Yup.string(),
    department: Yup.string(),
    streetAndNumberOrPoBox: Yup.string().required(
      intl.formatMessage({
        id: 'checkout.streetAndNumberOrPoBox.error.required',
      })
    ),
    postcodeAndCity: Yup.string().required(
      intl.formatMessage({ id: 'checkout.postcodeAndCity.error.required' })
    ),
    phone: Yup.string(),

    email: Yup.string()
      .email(intl.formatMessage({ id: 'checkout.email.error' }))
      .required(intl.formatMessage({ id: 'checkout.email.error.required' })),
    message: Yup.string(),
    termsOfPrivacy: Yup.boolean().oneOf(
      [true],
      intl.formatMessage({ id: 'checkout.termsOfPrivacy.error.required' })
    ),
    friendlyCaptchaCode: Yup.string().nullable(),
  })

  type ShoppingCartFormValues = {
    firstname: string
    lastname: string
    company: string
    department: string
    streetAndNumberOrPoBox: string
    postcodeAndCity: string
    phone: string
    email: string
    message: string
    termsOfPrivacy: boolean
    friendlyCaptchaCode: string
    products?: Array<IFormBasketItem> | []
  }

  interface IFormBasketItem {
    title: string
    amount: number
  }

  const initialValues: ShoppingCartFormValues = {
    firstname: '',
    lastname: '',
    company: '',
    department: '',
    streetAndNumberOrPoBox: '',
    postcodeAndCity: '',
    phone: '',
    email: '',
    message: '',
    termsOfPrivacy: false,
    friendlyCaptchaCode: '',
  }

  function _handleSubmit(
    values: ShoppingCartFormValues,
    { resetForm, setSubmitting }: FormikHelpers<ShoppingCartFormValues>
  ): void {
    const finalValues = { ...values }

    basket.forEach((basketItem) => {
      if (basketItem && !basketItem.deleted) {
        finalValues['products'] = finalValues['products'] ?? []
        finalValues['products'].push({
          title: basketItem.name,
          amount: basketItem.amount,
        })
      }
    })

    if (finalValues && finalValues?.products?.length) {
      sendShoppingCart(finalValues)
        .then((response: Response) => {
          if (response.status === 202) {
            resetForm()

            navigate('/aktuelles-und-downloads/thanks/')
            clearBasket()
          } else {
            toggleOverlay(true, {
              type: 'orderError',
              trackingID: 'checkoutError',
              width: 'sm',
              onAcceptance: () => toggleOverlay(false),
            })
          }
          setSubmitting(false)
        })
        .catch((error) => {
          console.log('order error', error)
          toggleOverlay(true, {
            type: 'orderError',
            trackingID: 'checkoutError',
            width: 'sm',
            onAcceptance: () => toggleOverlay(false),
          })
          setSubmitting(false)
        })
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={_handleSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting, touched }) => (
        <Form id="contact">
          <Grid container spacing={5} justifyContent="center">
            <Grid item xs={12} md={6}>
              <TextField
                id="firstname-input"
                name="firstname"
                type="text"
                variant="outlined"
                customVariant={'form'}
                label={intl.formatMessage({
                  id: 'checkout.firstname.label',
                })}
                autoComplete="given-name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="lastname-input"
                name="lastname"
                type="text"
                variant="outlined"
                customVariant={'form'}
                label={intl.formatMessage({
                  id: 'checkout.lastname.label',
                })}
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="company-input"
                name="company"
                type="text"
                variant="outlined"
                customVariant={'form'}
                label={intl.formatMessage({
                  id: 'checkout.company.label',
                })}
                autoComplete="organization"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="department-input"
                name="department"
                type="text"
                variant="outlined"
                customVariant={'form'}
                label={intl.formatMessage({
                  id: 'checkout.department.label',
                })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="streetAndNumberOrPoBox-input"
                name="streetAndNumberOrPoBox"
                type="text"
                variant="outlined"
                customVariant={'form'}
                label={intl.formatMessage({
                  id: 'checkout.streetAndNumberOrPoBox.label',
                })}
                autoComplete="address-line1"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="postcodeAndCity-input"
                name="postcodeAndCity"
                type="text"
                variant="outlined"
                customVariant={'form'}
                label={intl.formatMessage({
                  id: 'checkout.postcodeAndCity.label',
                })}
                autoComplete="postal-code"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="phone-input"
                name="phone"
                type="text"
                variant="outlined"
                customVariant={'form'}
                label={intl.formatMessage({
                  id: 'checkout.phone.label',
                })}
                autoComplete="tel"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                data-testid="email-input"
                id="email-input"
                type="email"
                name="email"
                variant="outlined"
                customVariant={'form'}
                label={intl.formatMessage({
                  id: 'contact.email.label',
                })}
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <RichTextEditor
                id="message-input"
                name="message"
                maxLength={750}
                placeholderLabel={intl.formatMessage({
                  id: `checkout.message.label`,
                })}
                showInfoText
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxField
                name="termsOfPrivacy"
                labelId="contact.privacy.label"
                labelLink="dataprivacy.path"
              />
              <FriendlyCaptcha name="friendlyCaptchaCode" />
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.button}
                disabled={isSubmitting}
                to="/aktuelles-und-downloads/warenkorb/"
                formSubmit
                icon="ChevronLeft"
                iconPosition={'left'}
                light
                hideLinkIcon
              >
                {intl.formatMessage({ id: 'checkout.button.basket' })}
              </Button>
              <Button
                className={classes.button}
                disabled={isSubmitting}
                formSubmit
                hideLinkIcon
              >
                {intl.formatMessage({ id: 'checkout.button.send' })}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
